<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"/>
    <a-card :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="入库单号">
                <a-input v-model.trim="queryParam.purchase_num" placeholder="根据入库单号搜素" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="入库时间">
                <a-range-picker @change="onDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="录入时间">
                <a-range-picker @change="onCreateDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="货名">
                <a-input v-model.trim="queryParam.good_name" placeholder="根据货名搜索"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="供应商">
                <a-select
                  showSearch
                  allowClear
                  :filter-option="filterOption"
                  placeholder="选择供应商"
                  v-model="queryParam.supplier_id"
                >
                  <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="货物规格">
                <a-input v-model.trim="queryParam.good_spec" placeholder="根据货物规格搜索"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="审核状态">
                <a-select allowClear v-model="queryParam.check_status" >
                  <a-select-option v-for="d in checkStatusOps" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="$refs.purchaseTable.refresh(true)" v-perms="'WMS_PURCHASE_QUERY'">查询</a-button>
        <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
        <a-button type="danger" icon="delete" :disabled="selectedRowKeys.length === 0" @click="handleDelete('all')">删除</a-button>
        <a-button type="query" icon="check" :disabled="selectedRowKeys.length === 0" @click="handleCheck('checked')" v-perms="'WMS_PURCHASE_CHECK'">审核通过</a-button>
        <a-button type="danger" icon="close" :disabled="selectedRowKeys.length === 0" @click="handleCheck('waiting')" v-perms="'WMS_PURCHASE_CHECK'">反审核</a-button>
      </div>

      <s-table
        ref="purchaseTable"
        size="small"
        rowKey="id"
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data="loadData"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="checkStatusSlots" slot-scope="text">
          <a-tag :color="text==='checked'?'green':'red'">{{text==='checked' ? '已审核' : '待审核'}}</a-tag>
        </span>
        <span slot="goodName" slot-scope="text">
          <a-popover placement="bottom">
            <template v-if="text && text.length > 0">
              <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length})单 ` : null }}</span>
              <span>{{ text[0] }}</span>
            </template>
            <template slot="content">
              <span v-for="(item, i) in text" :key="i" style="display:block">{{ item }}</span>
            </template>
          </a-popover>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
            <a-divider type="vertical" />
            <a @click="printSalePurchaseSheet(record)">销售入库单</a>
          </template>
        </span>
      </s-table>

      <a-modal
        :visible="printSalePurchaseSheetVisible"
        :confirmLoading="printSalePurchaseSheetLoading"
        :width="1250"
        :maskClosable="false"
        title="销售入库单"
        @cancel="printSalePurchaseSheetVisible = false">
        <div id="printSalePurchaseSheetBill">
          <SalePurchaseSheetBill
            :currentData="currentData"
            :balanceData="balanceData">
          </SalePurchaseSheetBill>
        </div>
        <template slot="footer">
          <a-button key="submit"
                    type="primary"
                    id="printSalePurchaseSheetBillBtn"
                    v-print="printSalePurchaseSheetBill">确认打印</a-button>
          <a-button type="primary" @click="printSalePurchaseSheetVisible = false">关闭</a-button>
        </template>
      </a-modal>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import { getPurchasePage, deletePurchase, getSalePurchaseSheet, checkPurchase } from '@/api/wms/sale'
import { getSupplier } from '@/api/common'
import { getFirstLetter } from '@/utils/util'
import SalePurchaseSheetBill from '@/views/wms/sale/bill/SalePurchaseSheetBill'

export default {
  components: {
    STable,
    SalePurchaseSheetBill
  },
  data() {
    return {
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '入库单号',
          dataIndex: 'purchase_num'
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          scopedSlots: { customRender: 'goodName' }
        },
        {
          title: '仓库',
          dataIndex: 'warehouse_name'
        },
        {
          title: '供应商',
          dataIndex: 'supplier_name'
        },
        {
          title: '录入人',
          dataIndex: 'creator'
        },
        {
          title: '录入时间',
          dataIndex: 'created_at'
        },
        {
          title: '入库时间',
          dataIndex: 'purchase_date'
        },
        {
          title: '审核状态',
          dataIndex: 'check_status',
          scopedSlots: { customRender: 'checkStatusSlots' }
        },
        {
          title: '审核人',
          dataIndex: 'check_user_name'
        },
        {
          title: '审核时间',
          dataIndex: 'check_date'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getPurchasePage(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      supplierOps: [], // 供应商
      checkStatusOps: [
        {
          value: 'waiting',
          name: '待审核'
        },
        {
          value: 'checked',
          name: '已审核'
        }
      ],
      currentData: {},
      balanceData: [],
      printSalePurchaseSheetVisible: false,
      printSalePurchaseSheetLoading: false,
      printSalePurchaseSheetBill: {
        id: `printSalePurchaseSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      }
    }
  },
  watch: {
    $route: function(newRoute) {
      if (newRoute.name === 'Purchase' && this.queryParam) {
        newRoute.meta.title = '进货'
        this.$nextTick(_ => {
          this.$refs.purchaseTable.refresh(newRoute.query.isEdit)
        })
      }
    }
  },
  created() {
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // 销售入库单
    printSalePurchaseSheet(record) {
      getSalePurchaseSheet({
        purchaseId: record.id
      }).then(res => {
        this.balanceData = res;
      }).finally(_ => {
        this.currentData = record;
        this.printSalePurchaseSheetVisible = true;
      });
    },
    handleCreate() {
      this.$router.push({
        name: 'PurchaseCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.$router.push({
        name: 'PurchaseCUR',
        params: record
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deletePurchase({ ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.purchaseTable.refresh(true)
          })
        }
      })
    },
    handleCheck(status) {
      const content = status === 'checked' ? '审核通过' : '反审核'
      this.$confirm({
        title: '确认提示',
        content: '确定要' + content,
        onOk: () => {
          checkPurchase({ purchase_ids: this.selectedRowKeys, check_status: status }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: content + '成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.purchaseTable.refresh(true)
          })
        }
      })
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['purchase_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['purchase_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['purchase_start_date'] = null
        this.queryParam['purchase_end_date'] = null
      }
    },
    onCreateDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['create_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['create_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['create_start_date'] = null
        this.queryParam['create_end_date'] = null
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
